import React, { useRef } from 'react';
import { connect } from 'react-redux';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import { SectionHeaderRightContainer } from 'Containers/desktop/citiesFeedPage';

import Conditional from 'Components/common/conditional';
import FeedSectionHeader from 'Components/common/feedSectionHeader';
import { CarouselRefActions } from 'Components/common/lateralCarousel';
import NavigationButtons from 'Components/desktop/navigationButtons';
import TopAttractionCarousel from 'Components/desktop/topAttractionCarousel';
import ViewAllLink from 'Components/desktop/viewAllLink';

import { useSwiperArrows } from 'Hooks/useSwiperArrows';
import { trackEvent } from 'Utils/analytics';
import { getAllPlacesToVisitURL } from 'Utils/collectionsUtils';
import { createSanitisedIdentifer } from 'Utils/gen';
import {
	getCollectionCardIdsByCityCode,
	getCollectionCardIdsByPersonaId,
} from 'Utils/stateUtils';
import { capitalizeOnlyFirstLetter } from 'Utils/stringUtils';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

/* Styled Components */
export const TopAttractionSectionWrapper = styled.div<{
	$hasTransparentBackground: boolean;
}>`
	width: 100%;
	background: ${({ $hasTransparentBackground }) =>
		$hasTransparentBackground ? 'transparent' : `${colors.GREY['F8']}`};

	.view-all-link-text {
		color: ${colors.GREY['44']};
		${getFontDetailsByLabel(TYPE_LABELS.BUTTON_SMALL)};
	}
`;

const SectionContainer = styled.div`
	max-width: 75rem;
	min-width: 62.5rem;
	margin: 0 auto;
	padding: 2.625rem 0 1.25rem;
	display: flex;
	flex-direction: column;
	width: 100%;

	.feed-section-header-title {
		color: ${colors.GREY['44']};
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_LARGE)};
	}
`;

type TopAttractionSectionProps = {
	cityName?: string;
	cityCode?: string;
	personaId?: number;
	lang?: string;
	isGlobalHomePage?: boolean;
	collectionIds?: any;
	currentCollectionId?: any;
	onScrollIntoView?: any;
	hasTransparentBackground?: boolean;
	showCityNameInHeading?: boolean;
	attractionsCountLimit?: number;
};

const TopAttractionSection = ({
	cityCode,
	cityName,
	personaId,
	lang,
	isGlobalHomePage,
	collectionIds,
	currentCollectionId,
	onScrollIntoView,
	hasTransparentBackground = false,
	showCityNameInHeading = true,
	attractionsCountLimit,
}: TopAttractionSectionProps) => {
	const attractionsCarouselRef = useRef<CarouselRefActions | null>(null);
	const { showLeftArrow, showRightArrow, onSlideChanged } = useSwiperArrows();
	const updatedCollectionIds = (collectionIds || []).filter(
		(collectionId: any) => collectionId !== currentCollectionId,
	);
	const pageHeading = isGlobalHomePage
		? strings.TOP_ATTRACTION.GLOBAL
		: showCityNameInHeading
		? strings.formatString(strings.TOP_ATTRACTION.CITY, cityName)
		: strings.TOP_ATTRACTION.NO_CITY;

	const sectionIdentifier = createSanitisedIdentifer(pageHeading);

	const handlePrev = () => {
		attractionsCarouselRef.current?.prevSlide();
		trackSwiperArrowClick('backward');
	};

	const handleNext = () => {
		attractionsCarouselRef.current?.nextSlide();
		trackSwiperArrowClick('forward');
	};

	const trackSwiperArrowClick = (direction: 'forward' | 'backward') => {
		trackEvent({
			eventName: ANALYTICS_EVENTS.CAROUSEL.CHEVRON_CLICKED,
			[ANALYTICS_PROPERTIES.DIRECTION]:
				capitalizeOnlyFirstLetter(direction),
			[ANALYTICS_PROPERTIES.SECTION]: pageHeading,
		});
	};

	return (
		<Conditional if={updatedCollectionIds.length > 0}>
			<TopAttractionSectionWrapper
				className='top-attracions-section-wrapper'
				$hasTransparentBackground={hasTransparentBackground}
			>
				<SectionContainer className='top-attracions-section-container'>
					<FeedSectionHeader
						title={
							isGlobalHomePage
								? strings.TOP_ATTRACTION.GLOBAL
								: showCityNameInHeading
								? strings.formatString(
										strings.TOP_ATTRACTION.CITY,
										cityName,
								  )
								: strings.TOP_ATTRACTION.NO_CITY
						}
						onScrollIntoView={onScrollIntoView}
						headingType={'h2'}
						newDesignSystem
					>
						<Conditional if={(collectionIds || []).length > 6}>
							<SectionHeaderRightContainer>
								<ViewAllLink
									to={
										isGlobalHomePage
											? `${
													lang && lang !== 'en'
														? `/${lang}`
														: ''
											  }/collections/`
											: getAllPlacesToVisitURL({
													cityCode,
													lang,
											  })
									}
									text={strings.CMN_SEE_ALL}
								/>
								<NavigationButtons
									showLeftArrow={showLeftArrow}
									showRightArrow={showRightArrow}
									prevSlide={handlePrev}
									nextSlide={handleNext}
								/>
							</SectionHeaderRightContainer>
						</Conditional>
					</FeedSectionHeader>
					<TopAttractionCarousel
						lang={lang}
						cityCode={cityCode}
						personaId={personaId}
						currentCollectionId={currentCollectionId}
						showAllCards={isGlobalHomePage}
						onSlideChanged={onSlideChanged}
						attractionsCarouselRef={attractionsCarouselRef}
						attractionsCountLimit={attractionsCountLimit}
						parentIdentifier={sectionIdentifier}
					/>
				</SectionContainer>
			</TopAttractionSectionWrapper>
		</Conditional>
	);
};

const mapStateToProps = (
	state: any,
	{ personaId = -1, cityCode }: { personaId?: number; cityCode: string },
) => ({
	collectionIds:
		personaId !== -1
			? getCollectionCardIdsByPersonaId(state, cityCode, personaId)
			: getCollectionCardIdsByCityCode(state, cityCode),
});

export default connect(mapStateToProps)(TopAttractionSection);
